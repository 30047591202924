import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink } from "reactstrap";

import Locale from "constants/Locale";
import useSearchParam from "hooks/useSearchParam";
import { UserContext } from "contexts/UserContext";
import RolType from "constants/roles";

const NavVehiculo = ({ authorized }) => {
  const { rol } = useContext(UserContext);
  const { i18n } = useTranslation();
  const { getSearchParam, setSearchParam } = useSearchParam();

  const tabSearchParam = getSearchParam("tab");

  const spanishAccessProtection = i18n.language === Locale.ES;
  const POSTVENTA_ROLES = [
    RolType.AFTER_SALES_AGENT,
    RolType.AFTER_SALES_SUPERVISOR,
    RolType.LEGAL_ADVISER,
    RolType.GENERAL_ADMIN,
    RolType.FINANCING_AGENT,
    RolType.USER_MANAGER,
    RolType.TRADING_MANAGER,
    RolType.WARRANTY
  ];

  const DOCU_ROLES = [
    RolType.GENERAL_ADMIN,
    RolType.ADMINISTRATION,
    RolType.TRADING_AGENT,
    RolType.TRADING_MANAGER,
    RolType.AFTER_SALES_AGENT,
    RolType.AFTER_SALES_SUPERVISOR,
    RolType.FINANCING_AGENT,
    RolType.LEGAL_ADVISER,
  ];

  const PREPARACION_ROLES = [
    RolType.GENERAL_ADMIN,
    RolType.TRADING_MANAGER,
    RolType.TRADING_AGENT,
    RolType.TRADING_MANAGER_PILOT,
    RolType.TRADING_AGENT_PILOT,
    RolType.WARRANTY
  ];

  return (
    <div>
      <Nav tabs className="pointer">
        <NavItem>
          <NavLink
            className={tabSearchParam === "datos" ? "active" : ""}
            onClick={() => setSearchParam("tab", "datos")}
          >
            Datos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabSearchParam === "infoadicional" ? "active" : ""}
            onClick={() => setSearchParam("tab", "infoadicional")}
          >
            Info Adicional
          </NavLink>
        </NavItem>
        {PREPARACION_ROLES.some((r) => rol.indexOf(r) >= 0) && (
          <NavItem>
            <NavLink
              className={tabSearchParam === "preparacion" ? "active" : ""}
              onClick={() => setSearchParam("tab", "preparacion")}
            >
              Preparación
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className={tabSearchParam === "interes" ? "active" : ""}
            onClick={() => setSearchParam("tab", "interes")}
          >
            Interés generado
          </NavLink>
        </NavItem>
        {DOCU_ROLES.some((r) => rol.indexOf(r) >= 0) &&
          authorized &&
          spanishAccessProtection && (
            <NavItem>
              <NavLink
                className={tabSearchParam === "documentacion" ? "active" : ""}
                onClick={() => setSearchParam("tab", "documentacion")}
              >
                Documentación
              </NavLink>
            </NavItem>
          )}

        {POSTVENTA_ROLES.some((r) => rol.indexOf(r) >= 0) && (
          <NavItem>
            <NavLink
              className={tabSearchParam === "postventa" ? "active" : ""}
              onClick={() => setSearchParam("tab", "postventa")}
            >
              Postventa
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </div>
  );
};
export default NavVehiculo;
