import React, { useState, useEffect, useContext, useCallback } from "react";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Form } from "react-bootstrap";
import Select from "react-dropdown-select";
import styles from "./index.module.scss";
import { UserContext } from "contexts/UserContext";
import { errorToast, successToast } from "../../../helpers/toastFunctions";
import Filter from "components/Filter";
import BankOptions from "constants/bankOptions";
import { useTranslation } from "react-i18next";
import FormattedNumberInput from "components/FormattedNumberInput";
import { insouranceOptions, yesOrNotSelect } from "constants/selectOptions";
import { MdInfo } from "react-icons/md";
import validationIBAN from "helpers/validationIBAN";

const ModalContrato = ({ setModal, status, lead, client, reservation, setIdContract, ganado }) => {
	const { t } = useTranslation("modalContrato");
	const { user } = useContext(UserContext);
	const [data, setData] = useState({
		DNI: reservation ? reservation.dni : null,
		telefono: client.telefono1,
		reserva: 500,
		precio: lead.pvp_financiado,
		descuento_financia: lead.pvp_contado - lead.pvp_financiado,
		km: lead.kilometros.replaceAll(".", ""),
		email: client.email,
		fecha_nacimiento: new Date(),
		direccion: "",
		poblacion: client.poblacion,
		CP: "",
		nombre: reservation
			? reservation.nombre + " " + reservation.apellido1 + " " + reservation.apellido2
			: client.nombre,
		marca: lead.nombre_marca_v,
		modelo: lead.nombre_modelo_v,
		matricula: lead.matricula,
		bastidor: lead.bastidor,
		notas: "",
		observaciones: "",
		carroceria: lead.carroceria,
		iva: lead.aplica_iva
			? [{ label: "Deducible", value: true }]
			: [{ label: "No deducible", value: false }],
		transferencia: 350,
		financia: [{ label: "Sí", value: true }],
		garantia: [{ label: "No", value: false }],
		garantia_extra: [{ label: "No", value: false }],
		importe_garantia: 0,
		importe_garantia_extra: 0,
		iban_garantia_extra:"",
		transporte: [{ label: "No", value: false }],
		importe_transporte: 0,
		provincia: client.provincia,
		id_provincia: client.id_provincia,
		provincia_local: null,
		gestoria: "APM",
		id_gestoria: 1,
		precio_financia: lead.pvp_financiado,
		precio_transferencia: 350,
		precio_tpv_tienda: 0,
		precio_pasarela: 0,
		precio_contado: 0,
		precio_coche_forma_pago: 0,
		fecha_ultima_itv: new Date(),
		fecha_caducidad_itv: new Date(),
		tipo_venta: [{ label: "Presencial", value: "Presencial" }],
		entrega: [{ label: "Tienda", value: "Tienda" }],
		local_entrega: [{ label: lead.nombre_local, value: lead.id_local_for_marketplace }],
		direccion_entrega: null,
		conformidades: "",
		buy_and_sell: lead.buy_and_sell,
		entrega_cambio: true,
		seguro_auto: [{ label: "No", value: false }],
		aseguradora_auto: insouranceOptions,
		financia_seguro: [{ label: "No", value: false }],
		importe_seguro: 0
	});

	const [precioTotalFinanciado, setPrecioTotalFinanciado] = useState(lead.pvp_financiado);
	const [companies, setCompanies] = useState(null);
	const [validating, setValidating] = useState(false);
	const KEYS_NOT_REQUIRED = [
		"observaciones",
		"notas",
		"transferencia",
		"importe_garantia",
		"importe_transporte",
		"direccion_entrega",
		"conformidades",
		"iban_garantia_extra"
	];

	const getLocalEmpresas = useCallback(async () => {
		const response = await post(API.GET_EMPRESAS_BY_LOCAL, {
			id_local: lead.id_local_for_marketplace
		});
		if (response) {
			setCompanies([
				{
					label: response[0].label,
					value: response[0].value
				}
			]);
		}
	}, [lead.id_local_for_marketplace]);

	const getProvinciaLocal = useCallback(
		async (id_local) => {
			const response = await post(API.GET_PROVINCIA_LOCAL, { id_local });
			if (response) {
				setData({ ...data, provincia_local: response });
			}
		},
		[data]
	);

	useEffect(() => {
		if (lead && !companies) {
			getLocalEmpresas();
			getProvinciaLocal(lead.id_local_for_marketplace);
		}
	}, [companies, data.matricula, getLocalEmpresas, getProvinciaLocal, lead]);

	const handleGestoria = (tipo, element) => {
		setData({
			...data,
			gestoria: element[0].label,
			id_gestoria: element[0].value
		});
	};

	const createDocument = async () => {
		if (!validating) {
			return errorToast(<span>{t("errors.errorAmount")}</span>);
		}
		if (!data.entrega_cambio) {
			KEYS_NOT_REQUIRED.push(
				"poblacion_entrega",
				"provincia_entrega",
				"domicilio_entrega",
				"cp_entrega"
			);
		}
		let err = false;
		Object.keys(data).forEach((key) => {
			if (data[key] === null || data[key] === "") {
				if (!KEYS_NOT_REQUIRED.includes(key)) {
					if (!err) {
						err = true;
					}
					return errorToast(
						<span>
							{t("errors.errorCompany")} {key}
						</span>
					);
				}
			}
		});
		// COMPROBAR LOS IMPORTES

		if (err) {
			return -1;
		}

		//DATA
		const datos = {
			bastidor: data.bastidor,
			pvp: +data.precio,
			aplica_iva: data.iva ? 1 : 0,
			matricula: data.matricula,
			nombre: data.nombre,
			telefono: +data.telefono,
			email: data.email,
			DNI: data.DNI,
			provincia: data.provincia,
			id_provincia: data.id_provincia,
			provincia_local: data.provincia_local[0].label,
			id_provincia_local: data.provincia_local[0].value,
			poblacion: data.poblacion,
			direccion: data.direccion,
			fecha_nacimiento: data.fecha_nacimiento,
			kilometros: +data.km,
			observaciones: data.observaciones,
			notas: data.notas,
			garantia: data.garantia[0].value,
			garantia_extra: data.garantia_extra[0].value,
			transporte: data.transporte[0].value,
			financia: data.financia[0].value,
			importe_garantia: +data.importe_garantia,
			importe_garantia_extra: +data.importe_garantia_extra,
			iban_garantia_extra:data.iban_garantia_extra,
			importe_transporte: +data.importe_transporte,
			importe_financia: data.financia[0].value ? +data.descuento_financia : 0,
			meses_financia: data.entidad_financiera
				? data.entidad_financiera[0].value === "Cetelem"
					? "18 meses"
					: "24 meses"
				: null,
			entidad_financiera: data.entidad_financiera ? data.entidad_financiera[0].value : null,
			coste_transferencia: +data.transferencia,
			gestoria: data.gestoria,
			id_gestoria: data.id_gestoria,
			pago_financiado: data.precio_financia,
			pago_transferencia: data.precio_transferencia,
			pago_tpv_tienda: data.precio_tpv_tienda,
			pago_tpv_pasarela: data.precio_pasarela,
			pago_efectivo: data.precio_contado,
			pago_coche_forma_pago: data.precio_coche_forma_pago,
			marca: data.marca,
			modelo: data.modelo,
			fecha_ultima_itv: data.fecha_ultima_itv,
			fecha_caducidad_itv: data.fecha_caducidad_itv,
			tipo_venta: data.tipo_venta[0].value,
			entrega: data.entrega[0].value,
			local_entrega: data.entrega[0].value === "Tienda" ? data.local_entrega[0].label : null,
			local_entrega_id: data.entrega[0].value === "Tienda" ? data.local_entrega[0].value : null,
			entrega_cambio: data.entrega_cambio,
			provincia_entrega: data.provincia_entrega,
			poblacion_entrega: data.poblacion_entrega,
			CP_entrega: data.CP_entrega,
			tipo: data.carroceria,
			CP: data.CP,
			conformidades: data.conformidades,
			local: lead.nombre_local,
			buy_and_sell: data.buy_and_sell,
			seguro_auto: data.seguro_auto[0].value,
			aseguradora_auto: data.aseguradora_auto[0].value,
			financia_seguro: data.financia_seguro[0].value,
			importe_seguro: data.importe_seguro
		};

		const company = await post(API.GET_EMPRESA_BY_ID, { id: companies[0].value });
		if (company && company.res) {
			datos.empresa_CIF = company.res.CIF;
			datos.empresa_direccion = company.res.direccion;
			datos.empresa_nombre = company.res.nombre;
			datos.empresa_provincia = company.res.provincia;
			datos.sello = company.res.sello;
		} else {
			return errorToast(<span>{t("errors.errorCompanyNotFound")}</span>);
		}

		//POST
		const d = {
			agente: user,
			vehiculo: reservation ? reservation.id_vehiculo : lead.vehicle_id,
			lead: reservation ? reservation.id_lead : lead.id_lead,
			cliente: reservation ? reservation.id_cliente : lead.id_cliente_lead,
			datos: JSON.stringify(datos),
			garantia: data.garantia_extra[0].value ? 3 : data.garantia[0].value ? 2 : 1
		};

		const createContract = await post(API.CREATE_CONTRACT_VENTA, { data: d });
		if (createContract && createContract.insertId) {
			setIdContract(createContract.insertId);
			setModal();
			return successToast("Contrato creado correctamente");
		} else {
			return errorToast(<span>{t("errors.errorContract")}</span>);
		}
	};

	const validateAmounts = () => {
		if (data.seguro_auto[0].value) {
			if (!data.financia[0].value && data.financia_seguro[0].value) {
				errorToast(<span>{t("errors.unfunded")}</span>);
				return;
			}
			if (data.importe_seguro <= 0) {
				errorToast(t("errors.amountInsourance"));
				return;
			}
		} 

		if (data.garantia_extra[0].value && !data.iban_garantia_extra) {
			return errorToast(t("errors.ibanRequired"));
		}
		if (data.garantia_extra[0].value && data.iban_garantia_extra) {
			if (!validationIBAN(data.iban_garantia_extra)) return errorToast(t("errors.ibanInvalid"));
		}
		const {
			precio_transferencia,
			precio_tpv_tienda,
			precio_pasarela,
			precio_contado,
			precio_coche_forma_pago,
			precio,
			importe_garantia_extra,
			importe_transporte,
			transferencia,
			importe_garantia,
			importe_seguro
		} = data;

		const Rfinancia = Number(precioTotalFinanciado);
		const Rtransferencia = Number(precio_transferencia);
		const Rtpv_tienda = Number(precio_tpv_tienda);
		const Rpasarela = Number(precio_pasarela);
		const Rcontado = Number(precio_contado);
		const Rcoche_forma_pago = Number(precio_coche_forma_pago);
		const importeTiposDePago =
			Rfinancia + Rtransferencia + Rtpv_tienda + Rpasarela + Rcontado + Rcoche_forma_pago;

		const Rpvp = Number(precio);
		const Rtransporte = Number(importe_transporte);
		const Ritransferencia = Number(transferencia);
		const Rgarantia = Number(importe_garantia);
		const RgarantiaExtra = Number(importe_garantia_extra);
		const RseguroAuto = Number(importe_seguro);

		const pvpTotal =
			Rpvp + Rtransporte + Ritransferencia + Rgarantia + RgarantiaExtra + RseguroAuto;

		if (importeTiposDePago !== pvpTotal) {
			return errorToast(<span>{t("errors.errorAmountValue")}</span>);
		}

		setValidating(true);
	};
	const cancelValidateAmounts = () => {
		setValidating(false);
	};

	const isValueCap = (imput, cap) => {
		const { value } = imput;
		const MAX_VALUE = cap;
		if (value <= MAX_VALUE) return true;
	};

	useEffect(() => {
		// Reset de garantia_Extra cuando quitamos garantia premiun
		if (!data.garantia[0]?.value && data.garantia_extra[0]?.value) { 
			setData((prevData) => ({
				...prevData,
				garantia_extra: [{ label: "No", value: false }],
				importe_garantia: 0,
				importe_garantia_extra: 0
			}));
			return;
		}
		setData((prevData) => ({
			...prevData,
			importe_garantia: data.garantia[0].value ? (lead.pvp_contado > 20000 ? 650 : 450) : 0,
			importe_garantia_extra: data.garantia_extra[0].value ? 290 : 0
		}));
	}, [lead.pvp_contado, data.garantia,data.garantia_extra]);

	useEffect(() => {
		if (data.financia_seguro[0].value) {
			return setPrecioTotalFinanciado(Number(data.precio) + Number(data.importe_seguro));
		}
		setPrecioTotalFinanciado(data.precio);
	}, [data]);

	return (
		<Modal
			className="modal-xl"
			centered
			isOpen={status}
			toggle={() => setModal()}
		>
			<ModalHeader>
				Crear Contrato {lead?.nombre_marca_v} {lead?.nombre_modelo_v} {lead?.matricula}
			</ModalHeader>
			<ModalBody className="p-4">
				<div
					className="clearfix mb-3"
					style={{
						padding: ".7rem",
						backgroundColor: "#2C2C2C",
						display: "flex",
						alignItems: "center",
						marginTop: "1rem",
						justifyContent: "space-between"
					}}
				>
					<span className="tittle ml-4 mr-auto">Datos del cliente</span>
				</div>

				<div className={`row ${validating ? styles.blockEdit : ""}`}>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Nombre completo:</span>
						<input
							className="w-100"
							value={data.nombre}
							onChange={(e) => setData({ ...data, nombre: e.target.value })}
						/>
						<span className="tittle_sub ml-1">Provincia:</span>
						<Filter
							multi={false}
							key={"Provincias"}
							sendData={(name, value) => {
								setData({ ...data, provincia: value[0].label, id_provincia: value[0].value });
							}}
							parentValues={[
								{
									label: data?.provincia,
									value: data?.id_provincia
								}
							]}
							name={"Provincias"}
							query={"provincias"}
						/>
						<span className="tittle_sub ml-1">Fecha nacimiento:</span>
						<Form.Control
							type="date"
							name="fecha_nacimiento"
							placeholder="Seleccione una fecha"
							value={data.fecha_nacimiento}
							onChange={(e) => setData({ ...data, fecha_nacimiento: e.target.value })}
						/>
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Teléfono:</span>
						<input
							className="w-100"
							type="number"
							value={data.telefono}
							onChange={(e) => setData({ ...data, telefono: e.target.value })}
						/>
						<span className="tittle_sub ml-1">Población:</span>
						<input
							className="w-100"
							value={data.poblacion}
							onChange={(e) => setData({ ...data, poblacion: e.target.value })}
						/>
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Email:</span>
						<input
							className="w-100"
							value={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
						<span className="tittle_sub ml-1">Dirección:</span>
						<input
							className="w-100"
							value={data.direccion}
							onChange={(e) => setData({ ...data, direccion: e.target.value })}
						/>
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">DNI/NIE:</span>
						<input
							className="w-100"
							value={data.DNI}
							onChange={(e) => setData({ ...data, DNI: e.target.value })}
						/>
						<span className="tittle_sub ml-1">C.P.:</span>
						<input
							className="w-100"
							value={data.CP}
							onChange={(e) => setData({ ...data, CP: e.target.value })}
						/>
					</div>
				</div>

				<div
					className="clearfix mb-3"
					style={{
						padding: ".7rem",
						backgroundColor: "#2C2C2C",
						display: "flex",
						alignItems: "center",
						marginTop: "1rem",
						justifyContent: "space-between"
					}}
				>
					<span className="tittle ml-4 mr-auto">Datos de venta</span>
				</div>

				<div className={`row ${validating ? styles.blockEdit : ""}`}>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Matrícula:</span>
						<input
							className="w-100"
							value={data.matricula}
							onChange={(e) => setData({ ...data, matricula: e.target.value })}
						/>
						<span className="tittle_sub ml-1">PVP Venta:</span>
						<input
							className="w-100"
							type="number"
							value={data.precio}
							onChange={(e) =>
								setData({ ...data, precio: e.target.value, precio_financia: e.target.value })
							}
						/>

						<span className="tittle_sub ml-1">Coste transferencia:</span>
						<input
							className="w-100"
							value={data.transferencia}
							onChange={(e) => setData({ ...data, transferencia: e.target.value })}
						/>
						<span className="tittle_sub ml-1">Financia:</span>
						<Select
							color={"#FF5D0E"}
							options={yesOrNotSelect}
							placeholder={"¿Desea financiar?"}
							values={data.financia}
							noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
							onChange={(value) => setData({ ...data, financia: value })}
						/>
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Kilómetros:</span>
						<input
							className="w-100"
							type="number"
							value={data.km}
							onChange={(e) => setData({ ...data, km: e.target.value })}
						/>
						<span className="tittle_sub ml-1">Aplica IVA:</span>
						<Select
							color={"#FF5D0E"}
							options={[
								{ label: "No deducible", value: "No deducible" },
								{ label: "Deducible", value: "Deducible" }
							]}
							placeholder={"Aplica IVA"}
							values={data.iva}
							noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
							onChange={(value) => setData({ ...data, iva: value })}
						/>
						<span className="tittle_sub ml-1">Fecha última ITV:</span>
						<Form.Control
							type="date"
							name="fecha_ultima_itv"
							placeholder="Seleccione una fecha"
							value={data.fecha_ultima_itv}
							onChange={(e) => setData({ ...data, fecha_ultima_itv: e.target.value })}
						/>
						{data.financia[0].value ? (
							<>
								<span className="tittle_sub ml-1">Descuento financiación:</span>
								<input
									className="w-100"
									type="number"
									value={data.descuento_financia}
									onChange={(e) => setData({ ...data, descuento_financia: e.target.value })}
								/>
							</>
						) : (
							""
						)}
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Bastidor:</span>
						<input
							className="w-100"
							value={data.bastidor}
							onChange={(e) => setData({ ...data, bastidor: e.target.value })}
						/>
						<span className="tittle_sub ml-1">Empresa:</span>
						<Filter
							className={styles.fiterEmpresa}
							key={"empresa"}
							multi={false}
							sendData={(name, value) => {
								setCompanies(value);
							}}
							parentValues={companies}
							name={"Empresa"}
							query={`empresas_local`}
							data={lead?.id_local_for_marketplace}
						/>
						<span className="tittle_sub ml-1">Fecha caducidad ITV:</span>
						<Form.Control
							type="date"
							name="fecha_caducidad_itv"
							placeholder="Seleccione una fecha"
							value={data.fecha_caducidad_itv}
							onChange={(e) => setData({ ...data, fecha_caducidad_itv: e.target.value })}
						/>
						{data.financia[0].value ? (
							<>
								<span className="tittle_sub ml-1">Entidad financiera:</span>
								<Select
									color={"#FF5D0E"}
									options={BankOptions}
									placeholder={"Entidad financiera"}
									values={data.entidad_financiera}
									noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
									onChange={(value) => setData({ ...data, entidad_financiera: value })}
								/>
							</>
						) : (
							""
						)}
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Provincia Local:</span>
						<Filter
							multi={false}
							key={"ProvinciaLocal"}
							sendData={(name, value) => {
								setData({ ...data, provincia_local: value });
							}}
							parentValues={data.provincia_local}
							name={"ProvinciaLocal"}
							query={"provincias"}
						/>
						<span className="tittle_sub ml-1">Gestoría:</span>
						<Filter
							key={"gestoria"}
							multi={false}
							sendData={handleGestoria}
							parentValues={[
								{
									label: data?.gestoria,
									value: data?.id_gestoria
								}
							]}
							name={"Gestorías"}
							query={`gestorias`}
						/>
					</div>
				</div>
				<div className={`row ${validating ? styles.blockEdit : ""}`}>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">
							Tipo de venta:
							<MdInfo
								color="#007bff"
								size={25}
								className="ml-2 md-36"
								id="tipo_venta"
								role="button"
							/>
							<UncontrolledTooltip
								style={{ opacity: 0.8 }}
								target="tipo_venta"
								autoOpen
							>
								La venta A DISTANCIA es en la que el cliente no asiste a ningún establecimiento
								Flexicar para comprar el vehículo
							</UncontrolledTooltip>
						</span>
						<Select
							color={"#FF5D0E"}
							options={[
								{ label: "Presencial", value: "Presencial" },
								{ label: "Online", value: "Online" }
							]}
							placeholder={"Tipo de venta"}
							values={data.tipo_venta}
							noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
							onChange={(value) => setData({ ...data, tipo_venta: value })}
						/>
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Transporte:</span>
						<Select
							color={"#FF5D0E"}
							options={yesOrNotSelect}
							placeholder={"Gastos transporte"}
							values={data.transporte}
							noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
							onChange={(value) => setData({ ...data, transporte: value })}
						/>
					</div>
					{data.transporte[0].value ? (
						<>
							<div className="col-md-3 col-sm-12">
								<span className="tittle_sub ml-1">Importe Transporte:</span>
								<input
									className="w-100"
									type="number"
									value={data.importe_transporte}
									onChange={(e) => setData({ ...data, importe_transporte: e.target.value })}
								/>
							</div>
							<div className="col-md-3 col-sm-12">
								<span className="tittle_sub ml-1">Forma de Entrega:</span>
								<Select
									color={"#FF5D0E"}
									options={[
										{ label: "Tienda", value: "Tienda" },
										{ label: "Domicilio", value: "Domicilio" }
									]}
									placeholder={"Entrega"}
									values={data.entrega}
									noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
									onChange={(value) => setData({ ...data, entrega: value })}
								/>
							</div>
						</>
					) : (
						""
					)}
				</div>
				<div className={`row ${validating ? styles.blockEdit : ""}`}>
					{data.entrega[0].value === "Tienda" ? (
						<>
							<div className="col-md-3 col-sm-12">
								<span className="tittle_sub ml-1">Tienda de Entrega:</span>
								<Filter
									key={"locales"}
									multi={false}
									sendData={(name, value) => {
										setData({ ...data, local_entrega: value });
									}}
									parentValues={data.local_entrega}
									name={"Local de entrega"}
									query={`sedes_locales`}
								/>
							</div>
						</>
					) : (
						<>
							<div className={`row ${validating ? styles.blockEdit : ""}`}>
								<div className="col-md-12 col-sm-12">
									<span
										style={{ cursor: "pointer", marginLeft: "30px" }}
										check
									>
										<input
											type="checkbox"
											onChange={() => {
												setData({
													...data,
													entrega_cambio: data.entrega_cambio ? false : true
												});
											}}
											checked={data.entrega_cambio}
										/>
										Utilizar dirección de facturación
									</span>
								</div>
							</div>
							{!data.entrega_cambio ? (
								<div className={`row ${validating ? styles.blockEdit : ""}`}>
									<div className="col-md-3 col-sm-12">
										<span className="tittle_sub ml-1">Provincia:</span>
										<Filter
											multi={false}
											key={"Provincias"}
											sendData={(name, value) => {
												setData({
													...data,
													provincia_entrega: value[0].label,
													id_provincia_entrega: value[0].value
												});
											}}
											parentValues={[
												{
													label: data?.provincia_entrega,
													value: data?.id_provincia_entrega
												}
											]}
											name={"Provincias"}
											query={"provincias"}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<span className="tittle_sub ml-1">Población:</span>
										<input
											className="w-100"
											value={data.poblacion_entrega}
											onChange={(e) => setData({ ...data, poblacion_entrega: e.target.value })}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<span className="tittle_sub ml-1">Dirección:</span>
										<input
											className="w-100"
											value={data.direccion_entrega}
											onChange={(e) => setData({ ...data, direccion_entrega: e.target.value })}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<span className="tittle_sub ml-1">C.P.:</span>
										<input
											className="w-100"
											value={data.CP_entrega}
											onChange={(e) => setData({ ...data, CP_entrega: e.target.value })}
										/>
									</div>
								</div>
							) : null}
						</>
					)}
				</div>
				<h6 className="mt-4">Conformidades sobre el estado del vehículo: </h6>
				<textarea
					style={{ width: "100%" }}
					value={data.conformidades}
					onChange={(e) => setData({ ...data, conformidades: e.target.value })}
					maxlength="300"
				/>
				<small>{data.conformidades.length}/300 carácteres</small>

				<div
					className="clearfix mb-3"
					style={{
						padding: ".7rem",
						backgroundColor: "#D1D1D1",
						display: "flex",
						alignItems: "center",
						marginTop: "1rem",
						justifyContent: "space-between"
					}}
				>
					<span className={`tittle ${styles.subTitle}`}>Garantía</span>
				</div>
				<div className={`row ${validating ? styles.blockEdit : ""}`}>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">¿Garantía premium?:</span>
						<Select
							color={"#FF5D0E"}
							options={yesOrNotSelect}
							placeholder={"Garantía"}
							values={data.garantia}
							noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
							onChange={(value) => setData({ ...data, garantia: value })}
						/>
					</div>
					{data.garantia[0].value && (
						<div className="col-md-3 col-sm-12">
							<span className="tittle_sub ml-1">Importe Garantía:</span>
							<input
								className="w-100"
								type="number"
								value={data.importe_garantia}
								onChange={(e) => setData({ ...data, importe_garantia: e.target.value })}
							/>
						</div>
					)}
				</div>
				{data.garantia[0].value && (
					<div className={`row ${validating ? styles.blockEdit : ""}`}>
						<div className="col-md-3 col-sm-12">
							<span className="tittle_sub ml-1">
								Extra Garantía:
								<MdInfo
									color="#007bff"
									size={25}
									className="ml-2 md-36"
									id="garantia-tooltip"
									style={{ cursor: "pointer" }}
								/>
							</span>

							<UncontrolledTooltip
								style={{ opacity: 0.8 }}
								target="garantia-tooltip"
								autoOpen
							>
								La Garantía Extendida amplía la cobertura de la Garantía Premium durante un año
								adicional a través de Caser
							</UncontrolledTooltip>
							<Select
								color={"#FF5D0E"}
								options={yesOrNotSelect}
								placeholder={"Garantía"}
								values={data.garantia_extra}
								noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
								onChange={(value) => setData({ ...data, garantia_extra: value })}
							/>
						</div>

						{data.garantia_extra[0].value && (
							<>
								<div className="col-md-3 col-sm-12">
									<span className="tittle_sub ml-1">Importe Extra Garantía:</span>
									<input
										className="w-100"
										type="number"
										value={data.importe_garantia_extra}
										onChange={(e) => setData({ ...data, importe_garantia_extra: e.target.value })}
									/>
								</div>
								<div className="col-md-4 col-sm-12">
									<span className="tittle_sub ml-1">IBAN Extra Garantía:</span>
									<input
										className="w-150"
										type="text"
										value={data.iban_garantia_extra}
										onChange={(e) => setData({ ...data, iban_garantia_extra: e.target.value })}
									/>
								</div>
							</>
						)}
					</div>
				)}
				<div
					className="clearfix mb-3"
					style={{
						padding: ".7rem",
						backgroundColor: "#D1D1D1",
						display: "flex",
						alignItems: "center",
						marginTop: "1rem",
						justifyContent: "space-between"
					}}
				>
					<spam className={`tittle ${styles.subTitle}`}>Seguro del vehículo</spam>
				</div>
				<div className={`row ${validating ? styles.blockEdit : ""}`}>
					<div className="col-md-3 col-sm-12">
						<span className="tittle_sub ml-1">Seguro Auto</span>
						<Select
							color={"#FF5D0E"}
							options={yesOrNotSelect}
							placeholder={"Seguro Auto"}
							values={data.seguro_auto}
							noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
							onChange={(value) => setData({ ...data, seguro_auto: value })}
						/>
					</div>
					{data.seguro_auto[0].value && (
						<>
							<div className="col-md-3 col-sm-12">
								<span className="tittle_sub ml-1">Compañia</span>
								<Select
									color={"#FF5D0E"}
									options={insouranceOptions}
									placeholder={"Compañia"}
									values={data.aseguradora_auto}
									noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
									onChange={(value) => setData({ ...data, aseguradora_auto: value })}
								/>
							</div>
							<div className="col-md-3 col-sm-12">
								<span className="tittle_sub ml-1">Financia</span>
								<Select
									color={"#FF5D0E"}
									options={[
										{ label: "Sí", value: true },
										{ label: "No", value: false }
									]}
									placeholder={"¿Desea financiar?"}
									values={data.financia_seguro}
									noDataRenderer={() => <span style={{ textAlign: "center" }}>Sin datos</span>}
									onChange={(value) => setData({ ...data, financia_seguro: value })}
								/>
								{!data.financia[0].value && data.financia_seguro[0].value && (
									<span
										className="text-danger"
										style={{ textAlign: "center" }}
									>
										Debes seleccionar “Financia: Sí” en Datos de la venta.
									</span>
								)}
							</div>

							<div className="col-md-3 col-sm-12">
								<span className="tittle_sub ml-1">Importe Seguro Auto</span>
								<FormattedNumberInput
									isAllowed={(value) => isValueCap(value, 9999)}
									onChange={(value) => setData({ ...data, importe_seguro: value })}
									value={data.importe_seguro}
								></FormattedNumberInput>
							</div>
						</>
					)}
				</div>

				<div
					className="clearfix mb-3"
					style={{
						padding: ".7rem",
						backgroundColor: "#2C2C2C",
						display: "flex",
						alignItems: "center",
						marginTop: "1rem",
						justifyContent: "space-between"
					}}
				>
					<span className="tittle ml-4 mr-auto">Importes de pago</span>
				</div>
				<span style={{ padding: "10px", border: "3px solid orange", borderRadius: "10px" }}>
					⚠️ La suma de los pagos debe coincidir con los campos PVP, transferencia, transporte,
					garantía y seguro de auto indicados arriba.
				</span>
				<div
					className={`row ${validating ? styles.blockEdit : ""}`}
					style={{ marginTop: "10px" }}
				>
					<div className="col-md-2 col-sm-12">
						<span className="tittle_sub ml-1">Financiación:</span>
						<input
							className="w-100"
							type="number"
							value={precioTotalFinanciado}
							onChange={(e) => setPrecioTotalFinanciado(e.target.value)}
						/>
					</div>
					<div className="col-md-2 col-sm-12">
						<span className="tittle_sub ml-1">Transferencia:</span>
						<input
							className="w-100"
							type="number"
							value={data.precio_transferencia}
							onChange={(e) => setData({ ...data, precio_transferencia: e.target.value })}
						/>
					</div>
					<div className="col-md-2 col-sm-12">
						<span className="tittle_sub ml-1">TPV Tienda:</span>
						<input
							className="w-100"
							type="number"
							value={data.precio_tpv_tienda}
							onChange={(e) => setData({ ...data, precio_tpv_tienda: e.target.value })}
						/>
					</div>
					<div className="col-md-2 col-sm-12">
						<span className="tittle_sub ml-1">TPV Pasarela:</span>
						<input
							className="w-100"
							type="number"
							value={data.precio_pasarela}
							onChange={(e) => setData({ ...data, precio_pasarela: e.target.value })}
						/>
					</div>
					<div className="col-md-2 col-sm-12">
						<span className="tittle_sub ml-1">Efectivo:</span>
						<input
							className="w-100"
							type="number"
							value={data.precio_contado}
							onChange={(e) => setData({ ...data, precio_contado: e.target.value })}
						/>
					</div>
					<div className="col-md-2 col-sm-12">
						<span className="tittle_sub ml-1">Coche pago:</span>
						<input
							className="w-100"
							type="number"
							value={data.precio_coche_forma_pago}
							onChange={(e) => setData({ ...data, precio_coche_forma_pago: e.target.value })}
						/>
					</div>
				</div>
				<h6 className="mt-4">Introducir información sobre la forma de pago</h6>
				<textarea
					style={{ width: "100%" }}
					value={data.notas}
					onChange={(e) => setData({ ...data, notas: e.target.value })}
				/>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => (validating ? cancelValidateAmounts() : validateAmounts())}
				>
					{validating ? "Cancelar validación" : "Validar datos"}
				</button>

				<div
					className="clearfix mb-3"
					style={{
						padding: ".7rem",
						backgroundColor: "#2C2C2C",
						display: "flex",
						alignItems: "center",
						marginTop: "1rem",
						justifyContent: "space-between"
					}}
				>
					<span className="tittle ml-4 mr-auto">Observaciones</span>
				</div>
				<h6 className="mt-1">Introduce notas o comentarios en el contrato</h6>
				<textarea
					style={{ width: "100%" }}
					value={data.observaciones}
					onChange={(e) => setData({ ...data, observaciones: e.target.value })}
				/>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => createDocument()}
				>
					Crear Contrato
				</button>
			</ModalBody>
			<ModalFooter>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => setModal()}
				>
					Cerrar
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalContrato;
